var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_c('v-toolbar',{attrs:{"elevation":"0"}},[_c('v-btn',{attrs:{"icon":"","to":{name:'BookList'},"exact":""}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('v-spacer'),(!_vm.isNew)?_c('v-slide-x-transition',{attrs:{"mode":"in-out"}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.edit),expression:"edit"}]},[(_vm.edit)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.cancelEdit}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cancel")])],1)]}}],null,false,3933788412)},[_vm._v(" "+_vm._s(_vm.$t('app.cancel'))+" ")]):_vm._e()],1)]):_vm._e(),(_vm.edit)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":!_vm.form},on:{"click":_vm.save}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-save")])],1)]}}],null,false,1645321653)},[_vm._v(" "+_vm._s(_vm.$t('app.save'))+" ")]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.edit = true}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mh-wiggle"},[_vm._v("mdi-pencil")])],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t('app.edit'))+" ")]),(_vm.showReturnButton)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.edit},on:{"click":_vm.returnBook}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-arrow-down-right")])],1)]}}],null,false,714247026)},[_vm._v(" "+_vm._s(_vm.$t('bookDetails.returnBook'))+" ")]):_vm._e()],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('h3',[_vm._v(_vm._s(_vm.book.title))])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_vm._v(" ")]),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"1"}},[_c('v-divider',{attrs:{"vertical":""}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-form',{staticClass:"pt-4",model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}},[_c('v-text-field',{attrs:{"label":_vm.$t('bookDetails.isbn.label'),"dense":"","outlined":_vm.edit,"readonly":!_vm.edit,"persistent-placeholder":"","rules":[function (v) { return (!!v && v.trim().length > 0) || _vm.$t('bookDetails.name.errors.required'); }]},model:{value:(_vm.book.isbn),callback:function ($$v) {_vm.$set(_vm.book, "isbn", $$v)},expression:"book.isbn"}}),_c('v-text-field',{attrs:{"label":_vm.$t('bookDetails.title.label'),"dense":"","outlined":_vm.edit,"readonly":!_vm.edit,"persistent-placeholder":""},model:{value:(_vm.book.title),callback:function ($$v) {_vm.$set(_vm.book, "title", $$v)},expression:"book.title"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }