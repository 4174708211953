<template>
    <v-card elevation="0">
        <v-card-title>
            <v-toolbar elevation="0">
                <v-btn icon :to="{name:'BookList'}" exact>
                    <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-slide-x-transition mode="in-out" v-if="!isNew">
                    <span v-show="edit">
                        <v-tooltip bottom v-if="edit">
                            <template v-slot:activator="{on, attrs}">
                                <v-btn v-on="on" v-bind="attrs" icon @click="cancelEdit">
                                    <v-icon>mdi-cancel</v-icon>
                                </v-btn>
                            </template>
                            {{$t('app.cancel')}}
                        </v-tooltip>
                    </span>
                </v-slide-x-transition>
                <v-tooltip v-if="edit" bottom>
                    <template v-slot:activator="{on, attrs}">
                        <v-btn icon v-on="on" v-bind="attrs" @click="save" :disabled="!form">
                            <v-icon>mdi-content-save</v-icon>
                        </v-btn>
                    </template>
                    {{$t('app.save')}}
                </v-tooltip>
                <v-tooltip v-else bottom>
                    <template v-slot:activator="{on, attrs}">
                        <v-btn v-on="on" v-bind="attrs" icon @click="edit = true">
                            <v-icon class="mh-wiggle">mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                    {{$t('app.edit')}}
                </v-tooltip>
                <v-tooltip v-if="showReturnButton" bottom>
                    <template v-slot:activator="{on,attrs}">
                        <v-btn v-on="on" v-bind="attrs" icon :disabled="edit" @click="returnBook">
                            <v-icon>mdi-arrow-down-right</v-icon>
                        </v-btn>
                    </template>
                    {{$t('bookDetails.returnBook')}}
                </v-tooltip>
                <!-- <v-tooltip v-if="!isNew" bottom>
                    <template v-slot:activator="{on,attrs}">
                        <v-btn v-on="on" v-bind="attrs" icon @mouseenter="deleteIcon = 'mdi-delete-empty'" @mouseleave="deleteIcon = 'mdi-delete'" :disabled="edit">
                            <v-icon>{{deleteIcon}}</v-icon>
                        </v-btn>
                    </template>
                    {{$t('app.delete')}}
                </v-tooltip>-->
            </v-toolbar>
        </v-card-title>
        <v-card-text>
            <!-- <v-row>
                <v-col cols="12">
                    <v-alert v-if="book.student" dense type="info">
                        <v-row align="center">
                            <v-col class="grow">{{$t('bookDetails.bookIsRented', {student: book.student.name})}}</v-col>
                            <v-col class="shrink">
                                <v-btn>{{$t('bookDetails.returnBook')}}</v-btn>
                            </v-col>
                        </v-row>
                    </v-alert>
                </v-col>
            </v-row>-->
            <v-row>
                <v-col>
                    <h3>{{book.title}}</h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="5">&nbsp;</v-col>
                <v-col cols="1" class="d-flex justify-center">
                    <v-divider vertical></v-divider>
                </v-col>
                <v-col cols="6">
                    <v-form v-model="form" class="pt-4">
                        <v-text-field v-model="book.isbn" :label="$t('bookDetails.isbn.label')" dense :outlined="edit" :readonly="!edit" persistent-placeholder :rules="[(v) => (!!v && v.trim().length > 0) || $t('bookDetails.name.errors.required')]"></v-text-field>
                        <v-text-field v-model="book.title" :label="$t('bookDetails.title.label')" dense :outlined="edit" :readonly="!edit" persistent-placeholder></v-text-field>
                    </v-form>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    components: {},
    data() {
         return {
             deleteIcon: 'mdi-delete',
             edit: false,
             form: null,
             srcBook: null,
             book: {}
         }
    },
    computed: {
        bookId: {
            get() {
                return this.$route.params['id']
            }
        },
        isNew: {
            get() {
                return this.bookId == null
            }
        },
        
        showReturnButton() {
            return !this.isNew && this.book.student != null
        }
    },
    watch: {
        
    },
    methods: {
        cancelEdit() {
            this.book = {...this.srcBook};
            this.edit = false;
        },
        loadBook(bookId) {
            this.axios.get(`/books/${bookId}`).then(res => {
                var data = res.data.data
                this.srcBook = {...data};
                this.book = data;
            })
        },
        returnBook() {
            this.axios.delete(`/books/${this.book.id}`)
            .then(() => {
                this.$store.dispatch('snackbar/addSnackbar', {text: this.$t('app.message.bookReturned'), color: 'success', closeIcon: 'mdi-close'})
                this.loadBook(this.book.id)
            })
        },
        save() {
            if(!this.isNew){
                this.axios.put(`/books/${this.bookId}`, this.book).then(res => {
                    const data = res.data.data;
                    this.book = data
                    this.srcbook = {...data}
                }).finally(() => {
                    this.edit = false
                })
            }
            else {
                this.axios.post('/books', this.book).then(res => {
                    const data = res.data.data;
                    this.book = data;
                    this.srcBook = {...data}
                    
                }).finally(() => {
                    this.edit = false
                })
            }
        }
    },
    created() {
        if(this.bookId) {
            this.loadBook(this.bookId);
        }
        else {
            this.edit = true
        }
            
    }
}
</script>